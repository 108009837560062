import { makeAutoObservable } from 'mobx'
import { createContext, useContext } from 'react'
import { IS_INTEGRATED_VERSION } from 'src/config/ConfigManager'
import { ISendCodeResponse } from 'src/features/login/interfaces'
import { UserAttributeKey, UserAttributes } from './interfaces'

import authManager from '../../api/security/Auth'

import { EmbeddedCommunicationsManager } from 'src/api/embedded/EmbeddedCommunicationsManager'
import { Translate } from 'src/i18n'
import UserDataServiceProvider from 'src/services/UserDataServiceProvider'
import { Country } from '../../api/user/interfaces'
import {
  CustomDimension,
  GoogleTagManager,
} from '../../config/analytics/GoogleTagManager'
import { StorageKeys } from '../../config/constants'
import {
  VehicleWidgetDisplayState,
  vehicleWidgetStore,
} from '../../features/search/VehicleSearch/store/VehicleWidgetStore'
import { StoreInstances } from '../StoreInstancesContainer'
import {
  SELECTED_VEHICLE_KEY,
  STORAGE_SEARCH_STATE_KEY,
} from '../search/SearchStore'
import { addUserToSentry } from 'src/helpers/sentry'
import { getAwsConfig } from 'src/aws-exports'
import { hasVehicleInfoDetails } from 'src/helpers/utils'
import AllianceConnectServiceProvider from 'src/services/AllianceConnectServiceProvider'
import { CatalogIdType } from 'src/api/embedded/types/partsBasketSpec'
import history from 'src/helpers/history'
import {
  CatalogIndexTracker,
  CatalogLookupType,
} from 'src/api/metrics/CatalogIndexTracker'
import { DomType, UuidType } from 'src/services/attributes/AttributesModel'
import AttributesServiceProvider from 'src/services/AttributesServiceProvider'
import { Translate2 } from 'src/i18n/translate2'

export const UserStoreContext = createContext<UserStore | undefined>(undefined)
// https://stackoverflow.com/a/69735347
export const useUserStore = (): UserStore => {
  const userStore = useContext(UserStoreContext)
  if (!userStore) {
    throw new Error(
      'No UserStoreContext.Provider found when calling useUserStore.'
    )
  }
  return userStore
}

export enum MutablePreference {
  SEARCH_PREFERENCE = 'searchPreferences',
  SEARCH_PREFERENCE_VERSION = 'searchPreferencesVersion',
  EPEFEATURES_DEFAULTSTATE = 'epeFeatures_defaultState',
}

const defaultPreferences: UserAttributes = {
  language: '',
  defaultSort: '',
  defaultFilters: '',
  display_partsTypeAll: 'false',
  display_filtering: '',
  display_userFiltering: '',
  findit_partsProfessionalN: '',
  phoneNumber: '',
  findit_orderIfNotAvail: 'false',
  epeFeatures_buyersGuide: 'false',
  epeFeatures_defaultState: '',
  epeFeatures_labor: 'false',
  epeFeatures_plateToVin: 'false',
  EULA_accepted: '',
  orgName: '',
  userId: '',
  mclOrgId: '',
  orgId: '',
  wdorgId: '',
  searchPreferences: '',
  searchPreferencesVersion: '',
  searchPreferences_defaultSort: '',
  dds: '',
  cart_noteMaxSize: '' /* numeric */,
  cart_noteRequired: '' /* y/n */,
  cart_poMaxSize: '' /* numeric */,
  cart_pnMaxSize: '' /* numeric */,
  cart_poRequired: '' /* y/n */,
  partDomain: '',
  cart_multiCart: 'false',
  display_perCarQtyOne: 'false',
  screenName: '',
  courrierAcctId: '',
  treePath: '',
  shipmentStatusTrackerEnabled: 'false',
  cart_nonPrimaryLocWarningEnabled: 'false',
  cart_shipToOnCart: 'false',
  display_priceFld: '',
  promo_enabled: 'false',
  orderAllCarts: 'true',
  rma: '0',
  miscPreferences: '',
  cart_orderType: '',
}

export enum MiscPreferencesKeys {
  INTERCHANGE_PARTTYPES = 'I_ptype',
  INTERCHANGE_MANUFACTURERS = 'I_manufacturers',
  DEFAULT_VEHICLE_TYPE = 'defaultVehicleType',
}

type MiscPreferences = Partial<Record<MiscPreferencesKeys, string>>

export class UserStore {
  private _resetCredentialsUserName?: string

  private _resetCredentialsDestination?: string

  displayName = ''

  redirectToLogin = false

  utcOffset: number

  userHasLoaded = false

  loadingUser?: Promise<void>

  country?: Country

  userLogo?: string = undefined

  isImpersonating = false

  // User preferences, AKA: User Cache
  preferences: UserAttributes

  miscPreferences: MiscPreferences

  constructor() {
    this.preferences = defaultPreferences
    this.miscPreferences = JSON.parse(
      defaultPreferences.miscPreferences || '{}'
    )

    this.init()
    makeAutoObservable(this)
  }

  private init = async (): Promise<void> => {
    const date = new Date()
    this.utcOffset = date.getTimezoneOffset()
    let resolveLoadedUser:
      | ((value: void | PromiseLike<void>) => void)
      | undefined
    this.loadingUser = new Promise<void>((resolve) => {
      resolveLoadedUser = resolve
    })
    this.preferences = defaultPreferences
    this.miscPreferences = JSON.parse(
      defaultPreferences.miscPreferences || '{}'
    )
    this.setIsImpersonating(
      localStorage.getItem(StorageKeys.AUTO_LOGIN_IMPERSONATION_KEY) === 'true'
    )

    await this.buildFromUserCache()
    GoogleTagManager.setCustomDimension(
      CustomDimension.Domain,
      this.preferences?.partDomain
    )
    GoogleTagManager.setCustomDimension(
      CustomDimension.User,
      this.preferences?.userId
    )
    if (resolveLoadedUser) {
      resolveLoadedUser()
    }
  }

  public handleLogin = async (
    username: string,
    pass: string
  ): Promise<void> => {
    const u = await authManager.signIn(username, pass)
    if (!u) {
      throw new Error(Translate('invalidCredentials'))
    }
    this.setRedirectToLogin(false)
    localStorage.removeItem(StorageKeys.AUTO_LOGIN_IMPERSONATION_KEY)
    localStorage.removeItem(StorageKeys.AUTO_LOGIN_ORIGIN_KEY)
    localStorage.removeItem(StorageKeys.SALESPAD_KEY)
    await this.init()
  }

  public sendCode = async (username: string): Promise<ISendCodeResponse> => {
    const resp = await AllianceConnectServiceProvider.forgotPasswordOtp({
      userName: username,
    })
    if (!resp) {
      throw new Error('errorSendingCode')
    }
    return resp
  }

  public resetPassword = async (
    code: string,
    username: string,
    newPassword: string
  ): Promise<void> => {
    const resp = await AllianceConnectServiceProvider.resetPassword({
      userName: username,
      password: newPassword,
      otp: code,
    })
    if (!resp) {
      throw new Error('Error resseting password')
    }
  }

  setRedirectToLogin = (shouldRedirect: boolean): void => {
    this.redirectToLogin = shouldRedirect
  }

  getIsImpersonating = (): boolean => {
    return this.isImpersonating
  }

  setIsImpersonating = (isImpersonating: boolean): void => {
    this.isImpersonating = isImpersonating
  }

  refetchUserPreferences(): Promise<void> {
    return authManager.getUserAttributes().then((data) => {
      this.preferences = data
      this.miscPreferences = JSON.parse(
        this.preferences.miscPreferences || '{}'
      )
    })
  }

  buildFromUserCache = async (): Promise<void> => {
    try {
      await authManager.getCurrentToken()
    } catch (_e) {
      this.setRedirectToLogin(true)
      return
    }
    try {
      const [userCache, country, userLogo] = await Promise.all([
        authManager.getUserAttributes(),
        UserDataServiceProvider.getCountry(),
        UserDataServiceProvider.getUserLogo(),
      ])
      // Add user details to sentry.
      addUserToSentry({
        id: userCache.userId,
        username: userCache.screenName,
      })
      this.country = country
      this.userLogo = userLogo

      const updatedPreferences: UserAttributes = {
        ...this.preferences,
        ...userCache,
      }
      this.setUserPreferences(updatedPreferences)
      this.miscPreferences = JSON.parse(
        updatedPreferences.miscPreferences || '{}'
      )
      StoreInstances?.searchStore?.restoreFiltersFromCache()
    } catch (e) {
      StoreInstances.uiStore.displayErrorNotification(`Amplify error: ${e}`)
    }
    if (IS_INTEGRATED_VERSION) {
      await this.decodeVehicle()
      await this.lookupBySearchInfo()
    } else {
      const selectedVehicleSessionStorage = JSON.parse(
        sessionStorage.getItem(SELECTED_VEHICLE_KEY) || '{}'
      )

      if (
        selectedVehicleSessionStorage &&
        Object.keys(selectedVehicleSessionStorage).length > 0
      ) {
        vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.view)
      } else {
        vehicleWidgetStore.setDisplayState(VehicleWidgetDisplayState.select)
      }
    }
    this.setUserHasLoaded(true)
    StoreInstances.bannerPromotionStore.showBannerOnce()
  }

  private async lookupBySearchInfo(): Promise<void> {
    const { catalogId, indexId, searchTxt } =
      EmbeddedCommunicationsManager.searchInfo || {}

    if (!searchTxt || !catalogId || !indexId) {
      return undefined
    }
    // Clear the previous search info to prevent it from being restored.
    sessionStorage.setItem(STORAGE_SEARCH_STATE_KEY, '')
    if (
      catalogId === CatalogIdType.AST ||
      catalogId === CatalogIdType.SAT ||
      catalogId === CatalogIdType.GSA
    ) {
      CatalogIndexTracker.setIndex(CatalogLookupType.AST_NO_MATCH)
      await StoreInstances.searchStore.searchByText(searchTxt)
      StoreInstances.searchStore.handleAddBreadCrumb({
        page: Translate2('allProducts'),
        breadcumb: searchTxt,
        url: () => StoreInstances.searchStore.searchByText(searchTxt),
      })
      history.push('/searchResults')
      return undefined
    }
    return undefined
  }

  public setUserHasLoaded = (userHasLoaded: boolean): void => {
    this.userHasLoaded = userHasLoaded
  }

  /**
   * @deprecated
   * We need to call the saveOrUpdateAttribute endpoint.
   * @param key
   * @param value
   */
  public updateUserPreferenceAndSyncToCloud = (
    key: MutablePreference,
    value: string
  ): void => {
    this.updateUserPreference(key, value)
    authManager.updateUserAttributes(this.preferences)
  }

  /**
   * @deprecated
   * We need to call the saveOrUpdateAttribute endpoint.
   * @param key
   * @param value
   */
  private updateUserPreference = (
    key: MutablePreference,
    value: string
  ): void => {
    const updatedPreferences = {
      ...this.preferences,
      [key]: value,
    }
    this.preferences = updatedPreferences
  }

  setUserPreferences = (preferences: UserAttributes): void => {
    this.preferences = preferences
    this.miscPreferences = JSON.parse(preferences.miscPreferences || '{}')
  }

  public updateMiscPreferences = (preferences: MiscPreferences): void => {
    const newMiscPreferences = { ...this.miscPreferences, ...preferences }

    const payloadAttributeValue = JSON.stringify(newMiscPreferences)
    const { userId } = StoreInstances.userStore.preferences
    const payload = {
      uuid: UuidType.MISCPREFERENCES_DEFAULTS,
      domId: Number(userId),
      domType: Number(DomType.USR),
      attrValue: payloadAttributeValue,
    }
    AttributesServiceProvider.saveOrUpdateAttribute([payload])
  }

  public getUserAttribute = (key: UserAttributeKey): string | undefined => {
    return this.preferences[key]
  }

  signOut = async (reloadPage = true): Promise<void> => {
    const awsConfig = getAwsConfig()
    const cognitoUserKeyBase = `CognitoIdentityServiceProvider.${awsConfig.aws_user_pools_web_client_id}.${this.preferences?.userId}`
    await authManager.signOut()
    addUserToSentry(null)

    sessionStorage.clear()
    localStorage.removeItem(`${cognitoUserKeyBase}.deviceKey`)

    // TODO: need to check and delete the following localStorage keys:breadCrumbs,searches,bannerPromoDate,
    localStorage.removeItem(StorageKeys.AUTO_LOGIN_IMPERSONATION_KEY)
    const originToRedirect = localStorage.getItem(
      StorageKeys.AUTO_LOGIN_ORIGIN_KEY
    )
    localStorage.removeItem(StorageKeys.AUTO_LOGIN_ORIGIN_KEY)
    localStorage.removeItem(StorageKeys.SALESPAD_KEY)

    localStorage.removeItem(StorageKeys.CXMLDETAILS_KEY)
    localStorage.removeItem(StorageKeys.CONDENSEDVIEW_KEY)

    if (originToRedirect) {
      window.location.href = originToRedirect
    } else {
      if (reloadPage) {
        location.reload()
      }
    }
  }

  getLanguage = (): string => {
    const locale = this.preferences?.language ?? 'en_US'
    return locale.split('_')[0]
  }

  public getUserLanguage(): string {
    return this.preferences?.language?.replace('_', '-') ?? 'en-US'
  }

  getStatePreference = (): string => {
    if (this.preferences?.epeFeatures_defaultState) {
      return this.preferences.epeFeatures_defaultState
    }
    return 'TX'
  }

  decodeVehicle = async (): Promise<void> => {
    if (
      IS_INTEGRATED_VERSION &&
      EmbeddedCommunicationsManager.vehicleInfo &&
      hasVehicleInfoDetails(EmbeddedCommunicationsManager.vehicleInfo)
    ) {
      // awaiting as we need to wait for the decoding to complete before moving further.
      await vehicleWidgetStore.lookupVehicle(
        EmbeddedCommunicationsManager.vehicleInfo
      )
    }
  }

  get resetCredentialsUserName(): string {
    return this._resetCredentialsUserName || ''
  }

  set resetCredentialsUserName(userName: string) {
    this._resetCredentialsUserName = userName
  }

  get resetCredentialsDestination(): string {
    return this._resetCredentialsDestination || ''
  }

  set resetCredentialsDestination(sentTo: string) {
    this._resetCredentialsDestination = sentTo
  }
}
